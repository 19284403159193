export default [
  {
    url: "/",
    label: "home",
  },
  {
    url: "/collection",
    label: "collection",
  },
  {
    url: "/about/our-history",
    label: "about us",
  },
  {
    url: "/contact",
    label: "contacts",
  },
]
